import { template as template_270013360e3d4a4596f9500fa27d4795 } from "@ember/template-compiler";
const FKLabel = template_270013360e3d4a4596f9500fa27d4795(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
