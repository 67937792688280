import { template as template_9aae51b024d74dbbb8101fe2fdb1fd99 } from "@ember/template-compiler";
const FKControlMenuContainer = template_9aae51b024d74dbbb8101fe2fdb1fd99(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
